.referral__main {
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 80px 80px;
}

.referral__title {
  margin: 70px 0 40px 0;
  color: var(--brand-color-1);
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
}

.referral__info {
  margin: 30px 0 0 0;

  display: flex;
  flex-flow: row wrap;
  gap: 30px;
}


@media (max-width: 768px) {
  .referral__main {
    padding: 10px 16px 100px;
  }
}