.socials {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 15px;
  border-left: 1px solid var(--border-color-1);
  padding: 4px 0 4px 25px;
}

.socials a {
  display: flex;
  transition: color 0.3s;
  color: inherit;
  width: 22px;
  height: 22px;
}

.socials a:hover {
  color: #d273fa;
}
