.label {
	display: inline-block;
	cursor: pointer;
	width: 40px;
	height: 24px;
}

.input {
	opacity: 0;
	position: absolute;
	z-index: -1;
}

.fake-input {
	position: relative;
	display: inline-block;
	width: inherit;
	height: inherit;
	background-color: var(--switch-background);
	border-radius: 24px;
	transition: background-color .3s;
}

.fake-input::before {
	content: '';
	display: inline-block;
	width: 20px;
	height: 20px;
	background-color: #FAFAFA;
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 3px 1px rgba(0, 0, 0, 0.05);
	position: absolute;
	left: 2px;
	border-radius: 50%;
	top: 50%;
	transform: translateY(-50%);
}

.input:checked + .fake-input {
	background-color: #9250D3;
}

.input:checked + .fake-input::before {
	left: auto;
	right: 2px;
}
