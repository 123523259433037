.info {
  display: flex;
  flex-flow: column;
}

.info__field {
  margin-top: 20px;
}

.info__field-legend {
  display: flex;
  gap: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: var(--brand-color-7);
  margin-bottom: 10px;
}

.info__field input {
  font-size: 14px;
}