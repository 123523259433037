.section__header {
  margin-bottom: 30px;

  display: flex;
  align-items: center;

  color: #888888;
}

.section__header svg {
  width: 20px;
  height: 20px;
}

.section__header h6 {
  margin: 0 0 0 10px;
  font-size: 20px;
}