.stream__player {
  border: 1px solid var(--border-color-1);
  border-bottom: none;
  border-radius: 20px 20px 0 0;
  width: 100%;
  position: relative;
  padding-bottom: 56.33%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.stream__video,
.stream__remaining {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.stream__info {
  display: flex;
  flex-flow: column;
  gap: 16px;
  padding: 30px 50px;
  background: var(--background-color-2);
  border: 1px solid var(--border-color-1);
  border-top: none;
  border-radius: 0 0 28px 28px;
}

.stream__heading {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

.stream__name {
  cursor: pointer;
  display: flex;
  gap: 10px;
  color: var(--brand-color-1);
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  transition: opacity .3s;
  height: auto;
  max-width: 50%;
}

.stream__name p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stream__name > div > svg {
  height: 18px;
  width: 18px;
  transition: transform .6s;
}

.stream__name:hover p {
  opacity: .8;
}

.stream__name:hover > div > svg {
  transform: rotate(180deg);
}

.stream__subtitle {
  margin: 0;
  color: #6D6D6D;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.stream__description {
  margin: 5px 0 0 0;
  color: #999999;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
}

.stream__description span {
  cursor: pointer;
  color: #B969FF;
  margin-left: 5px;
  text-decoration: underline;
}

.warnings__container {
  position: absolute;
  left: 10px;
  right: 10px;
}

.warnings__container > .warning {
  margin-top: 10px;
  border-radius: 8px;
  color: var(--error-color);
  text-align: center;
  font-weight: 700;
  background: var(--background-color-2);
  border: 1px solid var(--border-color-1);
  padding: 5px 15px;
  white-space: pre-wrap;
}

@media (max-width: 768px) {
  .stream__player {
    margin-top: 25px;
  }

  .stream__player > iframe {
    border-radius: 0;
  }

  .stream__info {
    padding: 15px 20px;
    gap: 12px;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .stream__subtitle,
  .stream__description {
    font-size: 14px;
  }

  .stream__description {
    margin: 3px 0 0 0;
  }
}
