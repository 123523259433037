.popup__error {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #EC26B5;
}

.popup__error:empty {
    display: none;
}
