.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
}

.loading__circles {
    width: 50px;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-name: rotate;
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}