.button {
    background: var(--button-color);
    border: 1px solid var(--border-color-2);
    box-shadow: var(--box-shadow);
    border-radius: 22px;
    padding: 19px 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85px;
    cursor: pointer;
    box-sizing: border-box;
    transition: background .3s, border .3s, box-shadow .3s;
}

.button__image {
    width: 21px;
    height: 21px;
    filter: brightness(var(--button-icon-brightness));
    transition: filter .3s;
}

.button__action-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: var(--brand-color-3);
    transition: color .3s;
}
