.form {
    background: var(--background-color-2);
    border: 1px solid var(--border-color-7);
    box-shadow: 0px 42px 34px rgba(82, 67, 194, 0.3);
    border-radius: 16px;
    max-width: 392px;
    width: 100%;
    padding: 42px 48px 28px;
    box-sizing: border-box;
    transition: background .3s, border .3s;
}

.form__fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

.form__legend {
    margin-bottom: 44px;
    padding: 0;
    max-width: 196px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.form__legend_small-indent {
    margin-bottom: 22px;
}

@media (max-width: 768px) {
    .form {
        padding: 42px 35px 28px;
    }
}