.chip {
    position: relative;
    display: inline-flex;
    align-items: center;
    background: var(--border-color-7);
    border-radius: 25px;
    height: auto;
    padding: 13px 16px;
    width: auto;
    justify-content: center;
}

.chip__button {
    margin-left: 10px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    background-color: var(--border-color-8);
    color: var(--border-color-7);
    height: 20px;
    width: 20px;
    min-width: 20px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.chip__button svg {
    width: 10px;
    height: 10px;
    display: block;
}

.chip__text {
    display: inline-block;
    white-space: wrap;
    flex-direction: column;
    word-break: break-word;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chip__tooltip {
    background: var(--background-color-2);
    border: 1px solid var(--brand-color-5);
    border-radius: 5px;
    color: var(--brand-color-5);
    padding: 5px 9px;
    position: absolute;
    transition: visibility .3s;
    visibility: hidden;
    will-change: visibility;
    z-index: 10;
    right: -22%;
    top: 74%;
    transform: none;
    font-size: 13px;
    width: 350px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.chip__tooltip_visible {
    visibility: visible;
}

.chip__link {
    color: var(--brand-color-5);
    text-decoration: underline;
}

.chip__link:hover {
    text-decoration: none;
}

@media (max-width: 768px) {
    .chip {
        padding: 8px 12px;
        margin-right: 13px;
    }

    .chip__button {
        height: 18px;
        width: 18px;
        min-width: 18px;
        margin-top: 0;
    }

    .chip__button svg {
        width: 8px;
        height: 8px;
    }

    .chip__tooltip {
        font-size: 12px;
        padding: 4px 8px;
        width: 280px;
        right: -15px;
        white-space: normal;
        word-wrap: break-word;
        line-height: 1.3;
    }
}