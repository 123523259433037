.chat {
  height: 100%;
}

.chat__block {
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: auto;
  justify-content: space-between;
}

/* Captcha block */
.chat__captcha {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .7)
}

/* Widget settings button */
.chat__widget-button {
  min-height: 45px;
}

.chat__block {
  margin-top: 15px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.chat__messages {
  /* Reset li */
  list-style: none;
  margin: 0;

  height: 100%;
  padding: 20px 10px;
  border-radius: 10px 10px 0 0;
  overflow-y: scroll;
}

.chat__message + .chat__message {
  margin-top: 20px;
}

.chat__form {
  border-top: 1px solid rgba(224, 224, 224, 0.8);
}

.chat__technical-message {
  font-family: 'Mulish';
}

.link {
  color: var(--brand-color-5);
}

/* Animations */

.button-scroll-enter {
  opacity: 0;
}

.button-scroll-enter-active {
  opacity: 1;
  transition: opacity 0.3s !important;
}

.button-scroll-exit {
  opacity: 1;
}

.button-scroll-exit-active {
  opacity: 0;
  transition: opacity 0.3s !important;
}

@media (max-width: 768px) {
  .chat__messages {
    padding: 5px;
  }

  .chat__widget-button {
    margin: 24px 0 10px 0;
  }
}

@media screen and (max-width: 768px) {
  .chat__messages {
    height: calc(100vh - 345px);
  }
}