.status {
  display: flex;
  flex-flow: row;
}

.status__icon {
  position: relative;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status__icon > div {
  border-radius: 100px;
}

.status__icon-lower {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .1;
  animation: pulsation 1.5s infinite;
}

.status__icon-upper {
  z-index: 2;
  min-width: 13px;
  min-height: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.status__name {
  margin-left: 15px;
  font-weight: 600;
  font-size: 24px;
  line-height: 21px;
}

/* Offline status */
.status_offline .status__icon-lower {
  background: #ec26b5;
}

.status_offline .status__icon-upper {
  background: #ec26b5;
}

.status_offline .status__name {
  color: #ec26b5;
}

/* Planned status */
.status_planned .status__icon-lower {
  background: #F8CA28;
}

.status_planned .status__icon-upper {
  background: #F8CA28;
}

.status_planned .status__name {
  color: #F8CA28;
}

/* Online status */
.status_online .status__icon-lower {
  background: #cc00ff;
}

.status_online .status__icon-upper {
  background: linear-gradient(0deg, rgba(111, 0, 222, 0.2), rgba(111, 0, 222, 0.2)), linear-gradient(90deg, #D273FA 0%, #8FF5D6 100%, #8FF5D6 100%);
}

.status_online .status__name {
  background: linear-gradient(0deg, rgba(111, 0, 222, 0.2), rgba(111, 0, 222, 0.2)), linear-gradient(90deg, #D273FA 0%, #8FF5D6 100%, #8FF5D6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.status_disabled .status__icon-lower {
  background: #a7a7a7;
}

.status_disabled .status__icon-upper {
  background: #e1e0e0;
}

.status_disabled .status__name {
  color: #F8CA28;
}


@keyframes pulsation {
  0% {
    opacity: 0.4;
    transform: scale(0.5);
  }

  50% {
    opacity: 0;
    transform: scale(1.3);
  }

  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

@media (max-width: 768px) {
  .status__name {
    display: none;
  }
}