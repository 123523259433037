.actions {
    background: var(--background-color-3);
    border: 1px solid var(--border-color-4);
    border-radius: 28px 28px 0 0;
    padding: 23px 30px;

    transition: border .3s, background .3s;
}

.actions__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.769286px;
    color: var(--brand-color-1);
    transition: color .3s;
}

.actions__buttons {
    margin-top: 21px;
}

.button-with-image {
    min-height: 110px;
}