.react-datepicker__input-container {
    border: 1px solid var(--border-color-6);
    border-radius: 8px;
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    transition: border .3s;
}

.react-datepicker__input-container input {
    color: var(--brand-color-1);
    box-sizing: border-box;
    height: inherit;
    border: none;
    background-color: transparent;
    padding: 13px 16px;
    font-family: inherit;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    border-radius: inherit;
    width: 100%;
    outline: none;
    transition: color .3s;
}

.react-datepicker {
    --border-radius: 0.6em;
    
    background-color: transparent;
    border-radius: var(--border-radius);
}

/* *Triangle */
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #97a2f0;
}

/* * Header */
.react-datepicker__header {
    background: var(--main-gradient);
    border-top-right-radius: 0.4rem;
    border-top-left-radius: 0.5em;
    border-bottom: none;
}

/* Ноябрь 2022 */
.react-datepicker__current-month {
    font-family: 'Mulish';
    font-weight: 700;
    color: #fff;
}   

/* Пн, Вт, Ср, Чт... */
.react-datepicker__day-name {
    font-family: 'Mulish';
    color: #fff;
}

/* * Months */
.react-datepicker__month-container {
    background: var(--date-picker-background);
    border-radius: var(--border-radius);
}

/* 1, 2, 3, 4, ..., 31*/
.react-datepicker__day {
    font-family: 'Mulish';
    color: var(--brand-color-1);
    border-radius: 4px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    transition: 0.2s border-color;
}

.react-datepicker__day:not(.react-datepicker__day--selected):hover {
    background-color: transparent;
    border-bottom-color: var(--brand-color-5);
}

/* * Active styles */
.react-datepicker__day--selected {
    background-color: var(--brand-color-5) !important;
    border-radius: 50% !important;
    color: #fff;
}

/* Не понял для чего вообще существует это, но пока что отключил стили */
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:focus {
    outline: none;
    background-color: transparent;
}