.video-js {
  width: 100%;
  height: 100%;
}

/* В настройке плеера нет параметра displayError, поэтому скрываем ошибки через стили */
.video-js .vjs-error-display {
  display: none;
}

.video-js.vjs-error .vjs-big-play-button {
  display: initial;
}

/* Если плеер находится в состоянии загрузки, то отображаем постер, который скрывается через vjs-has-started */
.video-js.vjs-waiting .vjs-poster {
  display: initial;
}

.video-js.vjs-theme-fantasy .vjs-big-play-button:focus {
  background-color: transparent;
}

.video-js.vjs-waiting .vjs-big-play-button {
  display: none;
}
