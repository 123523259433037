.nav-button {
  width: 100%;

  padding: 8px 0;

  display: flex;
  flex-flow: column;
  align-items: center;

  font-size: 12px;
  color: var(--brand-color-1);

  opacity: .8;

  border-radius: 8px 8px 0 0;

  text-decoration: none;
}

.nav-button img {
  height: 22px;
  width: 22px;

  filter: brightness(var(--button-icon-brightness));

  margin-bottom: 5px;
}

.nav-button.active {
  background: var(--brand-gradient-3);
  font-weight: 700;
}