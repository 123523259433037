.field {
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  box-sizing: border-box;
  transition: border .3s;
}

.field__textarea {
  background-color: var(--input-background);
  border: 1px solid var(--border-color-6);
  border-radius: inherit;
  color: var(--brand-color-1);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  line-height: 21px;
  max-height: 350px;
  min-height: 50px;
  outline: none;
  padding: 13px 16px;
  resize: vertical;
  transition: background-color .3s, color .3s;
  width: 100%;
}

.field__error {
  margin-top: 10px;
  font-size: 13px;
  color: var(--error-color);
}

.field__counter {
  color: var(--brand-color-1);
  position: absolute;
  font-size: 12px;
  right: 0;
  bottom: -18px;
}

.container {
  border: 1px solid var(--border-color-6);
  border-radius: 8px;
  transition: border-color .3s;
}

.container_error {
  border-color: var(--error-color);
}