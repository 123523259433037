.button {
    width: 100%;
    font-family: inherit;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 10px 16px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: #000;
    box-sizing: border-box;
}

.button:focus {
    outline: none;
    box-shadow: var(--focus-shadow);
    transition: box-shadow .3s;
}

.button_primary {
    color: #FFFFFF;
    background-image: var(--main-gradient);
    border: 1px solid var(--border-color-3);
    transition: background-image .3s, border .3s;
}

.button_secondary {
    color: var(--brand-color-1);
    background: var(--secondary-button);
    border: 1px solid var(--border-color-2);
    transition: background .3s, border .3s;
}
