.popup__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 25px;
    line-height: 28px;
    color: var(--brand-color-5);
    transition: color .3s;
}

.popup__title-content {
    display: flex;
    align-items: center;
}

.popup__title-clarity {
    color: var(--brand-color-1);
    opacity: 0.6;
    font-size: 16px;
    margin-left: 6px;
}

.popup__title-clarity::before {
    content: '(';
}

.popup__title-clarity::after {
    content: ')';
}

.popup__title-close {
    /* Reset */
    cursor: pointer;
    outline: none;
    background: none;
    padding: 0;
    border: none;

    width: 25px;
    height: 25px;
}

@media screen and (max-width: 768px) {
    .popup__title {
        color: var(--brand-color-1);
        font-size: 16px;
    }
}