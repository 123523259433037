.vk-events-dropdown__container {
  position: relative;
  width: 100%;
}

.vk-events-dropdown__header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
  margin: 10px 0;
}

.vk-events-dropdown__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vk-events-dropdown__list {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.vk-events-dropdown__item {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: var(--brand-color-7);
  transition: border 0.3s;
  margin: 5px 0;
  border: 1px solid transparent;
}

.vk-events-dropdown__item:first-child {
  margin-top: 0;
}

.vk-events-dropdown__item:last-child {
  margin-bottom: 0;
}

.vk-events-dropdown__img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 7px;
}

.vk-events-dropdown__item_active {
  border: 1px solid #b969ff;
}

.vk-events-dropdown__item:hover:not(.vk-events-dropdown__item_active) {
  border: 1px solid var(--border-color-1);
}

.vk-events-dropdown__empty {
  text-align: center;
  font-size: 1.1rem;
  color: var(--border-color-1);
  font-weight: 700;
  margin-bottom: 10px;
}

.vk-events-dropdown__arrow {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -4px;
  right: 13px;
  transition: transform 0.3s;
}

.vk-events-dropdown__arrow_rotated {
  transform: rotate(180deg);
}