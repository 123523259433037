.truncated {
  margin: 5px 0 0 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 5px;
}

.truncated__text,
.truncated__more {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
}

.truncated__text {
  color: #999999;
  word-break: break-all;
}

.truncated__more {
  cursor: pointer;
  margin-left: 5px;
  padding: 0;
  color: #9250D3;
}