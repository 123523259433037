.info {
  flex: 1;
  background: var(--background-color-2);
  border: 1px solid var(--border-color-9);
  border-radius: 12px;
}

.info img {
  width: 100%;
  max-width: 90px;
  height: 110px;
}

/* Reset default margin */
.info__wrapper * {
  margin: 0;
}

.info__wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
}

.info__text {
  max-width: 390px;
}

.info__text h2 {
  color: var(--brand-color-1);
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
}

.info__text > p {
  margin-top: 15px;
  color: #9e9e9e;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}

@media (max-width: 768px) {
  .info__wrapper {
    padding: 22px;
  }

  .info img {
    display: none;
  }

  .info__text h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 33px;
  }

  .info__text > p {
    margin-top: 10px;
  }
}
