.greeting-image {
  border-radius: 28px;
  width: 50%;
  max-width: 344px;
  height: auto;
  background: var(--greeting-image-gradient);
}


@media (max-width: 768px) {
  .greeting-image {
    width: 100%;
    max-width: 100%;
  }
}
