.container {
    min-height: 100vh;
    background-image: var(--container-gradient);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .container {
        padding: 0 10px;
    }
}