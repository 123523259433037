.networks {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
}

.networks__item {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    cursor: pointer;
}