#root.light {
  /* Global background */
  --background-color-1: #F9F9F9;
  --background-color-2: #fff;
  --background-color-3: #F5F5F5;
  --background-color-4: #E4DCEC;
  --background-color-5: rgba(146, 80, 211, 0.2);
  --background-color-6: #000;
  --background-color-7: #000;
  --background-color-8: rgb(233, 233, 233);
  --background-color-9: rgba(255, 255, 255, .85);

  /* Global border colors */
  --border-color-1: #e6e5f2;
  --border-color-2: #e6e5f2;
  --border-color-3: #E9E9E9;
  --border-color-4: rgba(237, 237, 245, 0.602601);
  --border-color-5: #C0C0C0;
  --border-color-6: #c8c8c8;
  --border-color-7: #F0F0F0;
  --border-color-8: #D6D6D6;
  --border-color-9: #c7c7c7;

  /* Global text colors */
  --brand-color-1: #000000;
  --brand-color-2: #6943D6;
  --brand-color-3: #697A8D;
  --brand-color-4: #000;
  --brand-color-5: #9250D3;
  --brand-color-6: #242424;
  --brand-color-7: #535353;
  --brand-color-8: #848199;
  --brand-color-9: #3A3A3A;
  --brand-color-10: #3C3C3C;
  --brand-color-11: #fff;

  /* Error text */
  --error-color: #EC26B5;

  /* Global box shadows */
  --box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);

  /* Card list in tariff */
  --card-list-border: #fff;

  /* Input */
  --input-background: transparent;
  --focus-input: #9250D3;

  /* Greeting background */
  --greeting-color: #fff;
  --greeting-image-gradient: linear-gradient(47deg, #f9d4f0,#d0eff5);

  /* Switch */
  --switch-background: #E5E5E5;

  /* Mark */
  --mark: #9250D3;

  /* Colors for broadcast list */
  --list-1: #fff;
  --list-glass-1: rgba(255,255,255, 0.39);
  --list-2: rgba(0, 0, 0, 0);

  /*  Buttons */
  --button-icon-brightness: 1;
  --button-color: #fff;
  --secondary-button: #fff;

  /* Datepicker */
  --date-picker-background: #fff;

  /* Gradients */
  --main-gradient: linear-gradient(0deg,rgba(111,0,222,.2),rgba(111,0,222,.2)),linear-gradient(90deg,#d273fa,#8fddf5);

  --brand-gradient-1:
    linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),
    linear-gradient(
      90deg,
      transparent 75%,
      rgba(111, 0, 222, 0.2) 83%,
      rgba(111, 0, 222, 0.2) 100%
      ),
    linear-gradient(
      90deg,
      #FFFFFF 16.6%,
      #FFFFFF 53.2%,
      #D273FA 73%,
      #8FDDF5 100%
    );

  --brand-gradient-2:
    linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
    linear-gradient(
      90deg,
      transparent 75%,
      rgba(111, 0, 222, 0.2),
      rgba(111, 0, 222, 0.2)
    ),
    linear-gradient(
      90deg,
      #FFFFFF 16.6%,
      #FFFFFF 33.2%,
      #e5baf7 49.8%,
      #d896f5 66.4%,
      #D273FA 83%,
      #8FDDF5 100%
    );
  --brand-gradient-3: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(197, 178, 237, 0.237925) 39.06%, rgba(103, 63, 185, 0.3) 100%);

  --gradient-background: linear-gradient(0deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,.8)),linear-gradient(0deg,rgba(111,0,222,.2),rgba(111,0,222,.2)),linear-gradient(90deg,#d273fa,#8fddf5);
  --container-gradient: linear-gradient(0deg,rgba(111,0,222,.2),rgba(111,0,222,.2)),linear-gradient(90deg,#d273fa,#8fddf5);
}

#root.dark {
  /* Global background */
  --background-color-1: #282828;
  --background-color-2: rgb(53, 53, 53);
  --background-color-3: rgb(53, 53, 53);
  --background-color-4: rgb(50, 50, 50);
  --background-color-5: #555555ad;
  --background-color-6: rgb(53, 53, 53);
  --background-color-7: #5e5e5e;
  --background-color-8: rgb(102, 102, 102);
  --background-color-9: rgba(75, 75, 75, .85);

  /* Global border colors */
  --border-color-1: #5f5f5f;
  --border-color-2: rgb(156, 156, 156);
  --border-color-3: #6e6e6e;
  --border-color-4: #5f5f5f;
  --border-color-5: #ff47cb;
  --border-color-6: #707070;
  --border-color-7: #686868;
  --border-color-8: #424242;
  --border-color-9: #5e5e5e;
  --border-color-9: #5e5e5e;
  --border-color-10: #5e5e5e;

  /* Global text colors */
  --brand-color-1: rgb(243, 243, 243);
  --brand-color-2: #efb9ff;
  --brand-color-3: #efb9ff;
  --brand-color-4: #f880d6;
  --brand-color-5: #f86ed1;
  --brand-color-6: #fcfcfc;
  --brand-color-7: #a5a5a5;
  --brand-color-8: #f86ed1;
  --brand-color-9: #cacaca;
  --brand-color-11: rgb(247, 247, 247);

  /* Error text */
  --error-color: #ff3cc8;

  /* Global box shadows */
  --box-shadow: 0px 2px 16px rgba(159, 160, 167, 0.12);
  --active-button-shadow: 0px 2px 16px rgba(215, 99, 250, 0.2);

  /* Card list in tariff */
  --card-list-border: #5f5f5f;

  /* Mark */
  --mark: #f86ed1;

  /* Switch */
  --switch-background: #a7a7a7;

  /* Input */
  --input-background: #353535;
  --focus-input: #ff3cc8;

  /* Greeting background */
  --greeting-color: #414141;
  --greeting-image-gradient: linear-gradient(47deg, #ee72d0,#64cbde);

  /* Colors for list */
  --list-1: #3d3d3d;
  --list-glass-1: rgba(0,0,0, 0.35);
  --list-2: #494949;

  /*  Buttons */
  --button-icon-brightness: 30;
  --button-color: #444444;
  --secondary-button: rgb(70, 70, 70);

  /* Datepicker */
  --date-picker-background: #242221;

  /* Gradients */
  --main-gradient: linear-gradient(-10deg, rgba(111, 0, 222, 0.2), rgba(111, 0, 222, 0.2)), linear-gradient(90deg, rgb(250, 108, 207) 0%, #5dcfdf 100%);
  --brand-gradient-1: linear-gradient(
    90deg,
    #3d3d3d 16.6%,
    #3d3d3d 33.2%,
    #ac49b0 83%,
    #76b1f8 100%
  );

  --brand-gradient-2: linear-gradient(
    120deg,
    rgb(53, 53, 53) 16.6%,
    rgb(53, 53, 53) 33.2%,
    rgb(100, 100, 100) 83%,
    rgb(100, 100, 100) 100%
  );

  --brand-gradient-3: linear-gradient(120deg, rgb(100, 100, 100) 0%, rgb(100, 100, 100) 100%);

  --gradient-background: linear-gradient(120deg, rgb(53, 53, 53) 0%, rgb(53, 53, 53)100%);
  --container-gradient: linear-gradient(120deg, rgba(222, 115, 255, 0.603) 0%, rgba(80, 229, 255, 0.685) 100%);
}