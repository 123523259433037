.form {
  display: flex;
  flex-flow: column;
  row-gap: 25px;
}

.form__fieldset {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 2fr;
  
  border: 0;
  padding: 0;
  margin: 0;
  outline: none;
}

.form__field-title {
  margin-top: 13px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: var(--brand-color-1);
  transition: color 0.3s;
}

.form__current-email {
  margin-top: 13px;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--brand-color-1);
  transition: color 0.3s;
}

.form__error {
  color: var(--error-color);
  font-size: 14px;
}

.form__full-width {
  grid-column-start: 1;
  grid-column-end: 3;
}

@media (max-width: 768px) {
  .form {
    margin: 20px 0 0 0;
  }

  .form__fieldset {
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    gap: 7px;
  }

  .form__fieldset > span {
    color: var(--brand-color-9);
  }

  .form__fieldset > div:last-child {
    width: 100%;
  }

  .form__field-title {
    font-weight: 400;
  }
}


