.dashboard {
    display: grid;
    grid-template-columns: 64.052% auto;
    grid-template-rows: max-content 1fr;
    column-gap: 92px;
    row-gap: 70px;
    min-height: 100vh;
    padding: 30px 60px 0 60px;
    box-sizing: border-box;
    align-items: self-start;
}

.stream-planning {
    width: 100%;
    height: 60px;

    flex-flow: row;
    align-items: center;
    justify-content: center;
    
    background: var(--greeting-color);
    border: 1px solid var(--border-color-1);
}

.stream-planning img {
    margin-right: 20px;
}

@media (max-width: 768px) {
    .dashboard {
        display: flex;
        flex-flow: column;
        gap: 24px;

        padding: 24px 16px 100px;
    }
}

.dashboard_broadcasting-error {
    row-gap: 16px;
}

.dashboard .dashboard__actions {
    align-self: stretch;
}

.dashboard .dashboard__popup_show {
    display: block;
}
