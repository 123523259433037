.accordion {
  display: flex;
  flex-flow: column;
}

.accordion__title {
  cursor: pointer;
  display: flex;
  align-items: center;

  color: var(--brand-color-1);
  font-weight: 700;
  line-height: 21px;
}

.accordion__title svg {
  width: 14px;
  height: 9px;
  stroke: var(--brand-color-1);
}

.accordion__title__arrow {
  display: flex;
  align-items: center;

  margin: 2px 0 0 10px;
  
  transform: rotate(180deg);
  transform-origin: center;
}

.accordion__title__arrow.active {
  transform: rotate(0deg);
}

@media (max-width: 768px) {
  .accordion {
    width: 100%;
  }
}
