/* .form {
  padding: 44px;
} */

.heading {
  margin: 0;
  font-size: 24px;
  color: var(--brand-color-5);
  text-align: center;
}

.verbose {
  margin: 44px 0;
  font-size: 15px;
}

.verbose__hint {
  color: var(--error-color)
}

.verbose__main {
  color: var(--brand-color-1);
}