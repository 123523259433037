.checkbox__input {
    display: none;
}

.checkbox {
    display: grid;
    grid-template-columns: 23px auto;
    grid-column-gap: 10px;
    cursor: pointer;
}

.checkbox__fake-input {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    width: 21px;
    height: 21px;

    border: 1px solid #C8C8C8;
    border-radius: 8px;
}

.checkbox__fake-input::before {
    content: '';
    width: 16px;
    height: 12px;
    display: none;
    background-image: url(img/checked.svg);
    background-size: contain;
    background-repeat: no-repeat;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.checkbox__input:checked + .checkbox__fake-input::before {
    display: inline-block;
}

.checkbox__fake-input:focus {
    box-shadow: var(--focus-shadow);
    outline: none;
    transition: box-shadow .3s;
}

.checkbox__text {
    font-family: inherit;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    align-self: center;
    color: var(--brand-color-1);
    transition: color .3s;
}