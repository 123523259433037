.hero {
  color: #fff;
  height: 650px;
  background: url("./assets/background.svg") bottom center / cover;
}

.hero__wrapper {
  max-width: 1440px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
  margin: 0 auto;
  padding: 105px 80px 127px;
}

/* Text section */
.hero__left {
  max-width: 590px;
}

.hero__left > h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 61px;
}

.hero__left > p {
  margin: 18px 0 0 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
}

/* Copy field */
.hero__copy {
  margin-top: 30px;
  height: 45px;
  max-width: 385px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  background: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 8px;
  overflow: hidden;
}

.hero__copy p {
  margin: 0;
  padding: 11px 11px 11px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #767676;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
}

/* Copy btn */
.hero__copy-btn {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 17px;
  border-left: 1px solid #c7c7c7;
  transition: background 0.3s;
}

.hero__copy-btn_copied {
  background: #0dda51;
}

.hero__copy-btn:not(.hero__copy-btn_copied):hover {
  background: rgb(233, 233, 233);
}

/* Right side with image */
.hero__right img {
  width: 100%;
}

@media (max-width: 768px) {
  .hero {
    height: 550px;
    background-position: 39% 72%;
  }

  .hero__wrapper {
    padding: 60px 16px 0;
  }

  .hero__left {
    width: 100%;
    text-align: center;
  }

  .hero__left > h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 46px;
  }

  .hero__left > p {
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
  }

  .hero__copy {
    margin: 30px auto 0 auto;
  }

  .hero__right {
    display: none;
  }
}
