.projects { 
  display: flex;
  flex-direction: column;
  width: 308px; 
  background: var(--background-color-1);
  border: 1px solid var(--border-color-1);
  border-radius: 8px;
  padding: 10px;
  transition: background .3s, border .3s;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  box-sizing: initial;
}

/*
  =========================
  * Список продуктов
  =========================
*/
.projects__list {
  /* Сброс дефолтных стилей */
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Отступы между карточками */
.projects__list_card + .projects__list_card {
  margin-top: 10px;
}


/*
  =========================
  * Автор продуктов (Инвентос)
  =========================
*/
.projects__author {
  color: #9A9A9A;
  text-transform: uppercase;
  text-align: end;
  margin-top: 10px;
}
.projects__author a {
  color: inherit;
  text-decoration: none;
}
.projects__author a:visited {
  color: inherit;
}