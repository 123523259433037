.pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
    gap: 4px;
}

.pagination__item {
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: var(--brand-color-7);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
}

.pagination__item_no-pointer {
    cursor: default;
}

.pagination__item_active {
    background-color: #9250D3;
    color: #fff;
}

.pagination__button {
    width: 7px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination__button_rotate-180 {
    transform: rotate(180deg);
}

@media screen and (max-width: 768px) {
    .pagination {
        gap: 2px;
    }

    .pagination__item {
        width: 28px;
        height: 28px;
        font-size: 12px;
        line-height: 14px;
        border-radius: 3px;
    }

    .pagination__button {
        width: 5px;
        height: 8px;
    }

    .pagination__item:nth-child(n+3):nth-last-child(n+3) {
        display: none;
    }

    .pagination__item_active {
        display: inline-flex;
    }

    .pagination__item_active+.pagination__item {
        display: inline-flex;
    }

    .pagination__item_active~.pagination__item:first-of-type {
        display: inline-flex;
    }
}

@media screen and (max-width: 375px) {
    .pagination {
        gap: 1px;
    }

    .pagination__item {
        width: 24px;
        height: 24px;
        font-size: 11px;
        line-height: 13px;
    }

    .pagination__button {
        width: 4px;
        height: 7px;
    }
}