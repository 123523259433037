.remaining {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: no-repeat center / cover url("../assets/poster.svg");
}

.remaining:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.remaining__icon {
  position: relative;
  width: 64px;
  height: 64px;
}

.remaining__title {
  position: relative;
  margin: 25px 0 0 0;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  line-height: 18px;
}

.remaining__subtitle {
  position: relative;
  margin: 15px 0 0 0;
  color: #c9c9c9;
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
}
