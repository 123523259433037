.color-picker {
  position: relative;
}

/* Color picker preview */
.color-picker__preview {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 7px;

  padding: 6px;

  border: 1px solid var(--border-color-1);
  border-radius: 3px;

  transition: border-color .3s;
}

.color-picker__color {
  width: 18px;
  height: 18px;

  border-radius: 3px;
}

.color-picker__color-value {
  width: 50px;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 12px;
}

.color-picker__preview > img {
  margin-top: 2px;

  transition: transform .2s;
}

/* Arrow styles */
.opened {
  transform: rotate(180deg);
}

.closed {
  transform: rotate(0deg);
}

/* Color picker cover */
.color-picker__cover {
  z-index: 3;

  position: absolute;
  top: 40px;
  left: 50%;

  transform: translate(-50%, 0%);

  animation: scaleIn .5s forwards;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@media (max-height: 700px) {
  .color-picker__cover {
    top: initial;
    bottom: 40px;
  }
}

@media (max-width: 768px) {
  .color-picker__preview > img {
    margin-left: auto;
  }
}
