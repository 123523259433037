.menu {
  display: flex;
  flex-flow: column;
  background: var(--background-color-2);
  border: 1px solid var(--border-color-1);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/* Menu btn */
.menu__btn {
  cursor: pointer;
  padding: 13px 23px;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  background: var(--background-color-2);
  border-bottom: 1px solid var(--border-color-1);
  transition: background 0.3s;
}

.menu__btn:not(:first-child):hover {
  background: var(--background-color-8);
}

.menu__btn:last-child {
  border-bottom: none;
}

/* Menu btn icon */
.menu__btn svg {
  width: 18px;
  height: 18px;

  color: var(--brand-color-6);
}

/* Menu btn caption */
.menu__btn h6 {
  margin: 0 0 0 10px;
  padding: 0;

  font-size: 14px;
  font-weight: 600;

  color: var(--brand-color-6);
}
