.table {}

/* * Table ROW */
.table__row {
  position: relative;
  background: var(--list-1);
  color: var(--brand-color-1);
  border-radius: 28px;
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 40px;
  overflow: hidden;

  transition: background .3s, border .3s, color .3s; 
}
.table__row:not(.table__row_thead)+.table__row {
  margin-top: 20px;
}
.table__row-aborted {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--list-glass-1);
  transition: 
    background .3s, 
    box-shadow .3s;
}
.table__row-aborted-icon {
  width: 25px;
  margin-right: 15px;
}
.table__row-aborted-text {
  color: #D50000;
}

/* * Table ROW_HEAD */
.table__row_thead {
  background: none;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
}


/* * Table ROW_DATA */
.table__row_td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

@media (max-width: 768px) {
  .table__row {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    gap: 10px;

    box-shadow: var(--box-shadow);

    padding: 26px 24px;
  }

  .table__row_td > span:first-child {
    color: var(--brand-color-7);
    font-weight: 700;
  }

  .table__row_td > span:last-child{
    color: var(--brand-color-10);
  }
}

@media (max-width: 408px) {
  .table__row {
    padding: 26px 13px;
  }
}