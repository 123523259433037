.aside {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  background: var(--background-color-2);
  border: 1px solid var(--border-color-1);
  border-radius: 28px;
  transition: background .3s, border .3s;
  box-sizing: initial;
  height: 100%;
}

.aside__header {
  padding: 22px 25px 0;
}

.aside__tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.aside__tab {
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0;
  padding: 10px 25px;
  color: var(--brand-color-6);
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  border-radius: 12px;
  border: 1px solid transparent;
  transition: background .3s, border-color .3s, flex .3s;
}

.aside__tab-text {
  margin: 0;
}

.aside__tab:hover {
  background: var(--background-color-5);
}

.aside__tab_active {
  flex: 1.3;
  border: 1px solid var(--border-color-1);
  background: var(--background-color-5);
}

.aside__tab-icon {
  width: 16px;
  height: 16px;
}

.aside__management {
  width: 100%;
  min-height: 45px;
  white-space: nowrap;
  display: inline-flex;
  padding: 10px 16px;
  font-size: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.aside__content {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  flex: 1;
  min-height: 200px;
  overflow: auto;
}

.aside__empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
}

.empty-text {
  margin: 0;
  text-align: center;
  color: var(--text-color-secondary, var(--brand-color-6));
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}
