.page {
    padding: 25px 70px 20px;
    column-gap: 64px;
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    box-sizing: border-box;
    color: var(--brand-color-1);
    transition: color .3s;
}

.page__wrapper {
    padding: 0 16px;
}

.page__column {
    display: flex;
    flex-flow: column;
    gap: 20px;
}

.page__aside {
    display: grid;
    grid-template-rows: minmax(0, max-content) minmax(0, 100%);
    gap: 20px;
}

.page__section {
    padding: 30px 50px;
    background: var(--background-color-2);
    border: 1px solid var(--border-color-1);
    border-radius: 28px;
}

.page__section_gradient {
    background: var(--gradient-background);
    padding: 25px 30px;
    border-radius: 28px;
}

.aside .button {
    background: var(--background-color-2) !important;
    transition: background .3s;
}

.page__popup-description {
    margin-bottom: 48px;
}

@media (max-width: 1280px) {
    .page {
        grid-template-columns: minmax(0, 1.65fr) minmax(0, 1fr);
        padding: 30px 30px 80px;
        gap: 30px;
    }
}

@media (max-width: 768px) {
    .page {
        display: flex;
        flex-flow: column;
        align-items: center;

        padding: 24px 0 60px;
    }

    .page__section {
        padding: 25px 30px;
        margin: 15px 0;
        border-radius: 0;
        border-left: none;
        border-right: none;
    }
}