.projects {
  position: relative;
}

.projects__icon {
  cursor: pointer;
  width: 22px;
  height: 22px;
  transition: color 0.3s;
}

.projects__icon_active {
  color: #d273fa;
}

.projects__list {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 10;
  will-change: transform;
}