.structure {
  --popup-padding-y: 13px;
  --popup-padding-x: 25px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.structure__header {
  margin-bottom: 30px;
}

.structure__wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.structure__content {
  flex: 1;
}

.structure__footer {
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .structure {
    --popup-padding-y: 15px;
    --popup-padding-x: 20px;
    padding: 0;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .structure__header {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    background: var(--background-color-2);
    border-bottom: 1px solid var(--border-color-1);
    padding: var(--popup-padding-y) var(--popup-padding-x);
    z-index: 5;
    margin-bottom: 0;
    flex-shrink: 0;
  }

  .structure__wrapper {
    flex: 1;
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .structure__content {
    padding: 10px var(--popup-padding-x);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
  }

  .structure__footer {
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: var(--popup-padding-y) var(--popup-padding-x);
    background: var(--background-color-2);
    border-top: 1px solid var(--border-color-1);
    z-index: 5;
    margin-top: 0;
    flex-shrink: 0;
  }
}