.statistics {
  background: var(--background-color-2);
  border: 1px solid var(--border-color-9);
  border-radius: 12px;
}

.statistics__wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 170px;
  padding: 24px 0;
}

/* Stat block */
.statistics__stat {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 193px;
  text-align: center;
}

.statistics__stat-value {
  display: flex;
  align-items: center;
}

.statistics__stat-value h3 {
  margin: 0 0 0 5px;
  color: var(--brand-color-1);
  font-weight: 700;
  font-size: 32px;
  line-height: 33px;
}

.statistics__stat p {
  margin: 10px 0 0 0;
  color: #9e9e9e;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}

@media (max-width: 768px) {
  .statistics__wrapper {
    gap: 15px;
    padding: 12.5px 5px;
  }

  .statistics__stat {
    max-width: 102px;
  }

  .statistics__stat__value h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
  }

  .statistics__stat p {
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
  }
}
