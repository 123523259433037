.form {
  --border-bottom-radius: 10px;

  position: relative;
  display: flex;
  align-items: center;
  color: var(--brand-color-1);
  border-radius: 0 0 var(--border-bottom-radius) var(--border-bottom-radius);
}

.form__input {
  /* ? В идеале заиметь бы файл стилей по типу reset.css или normalize.css */
  /* Reset. */
  border: none;
  background: inherit;
  color: inherit;
  font-size: 14px;

  font-family: 'Mulish', sans-serif, 'Noto Color Emoji';

  padding: 22px 0px 22px 20px;
  width: 100%;
  outline: none;
  border-bottom-left-radius: var(--border-bottom-radius);
}

.form__submit {
  /* Reset */
  border: none;
  background: none;
  cursor: pointer;
  outline: none;

  padding: 0 20px;
  align-self: stretch;
  border-bottom-right-radius: var(--border-bottom-radius);
}
.form__submit path {
  stroke: var(--brand-color-1);
}

/* Sticky actions */
.form__action-scroll {
  position: absolute;
  top: calc(-75% - 15px);
  right: 15px;
  z-index: 2;
  border-radius: 50%;
}