.streams__heading {
  margin: 0;
  color: var(--brand-color-1);
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

.streams__list {
  margin-top: 30px;
  display: flex;
  flex-flow: column;
  gap: 15px;
}

.streams__heading {
  margin: 0;
  color: var(--brand-color-1);
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

.streams__search {
  width: 100%;
}

.streams__input {
  padding: 13px;
}

.stream {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid var(--border-color-1);
  transition: border .3s;
  margin-top: 20px;
}

.stream_selected {
  border: 1px solid #b969ff; 
}

.stream:hover {
  border: 1px solid #b969ff;
}