.notify-container {
  z-index: 100;
  max-width: 465px;
  width: 100%;
  position: fixed;
  right: 40px;
  top: 80px;
  display: flex;
  flex-flow: column;
  transition: top .3s;
}

.notify-container__notify {
  position: absolute;
  top: 0;
  transition: top .3s;
}

.notify-container.offset {
  top: 20px;
}

@media (max-width: 768px) {
  .notify-container {
    padding: 0 5px;
    top: 70px;
    left: 50%;
    right: inital;
    transform: translate(-50%, 0%);
    opacity: .97;
  }
}

/* Notify animation */
.notify-enter {
  max-height: 0;
  opacity: 0;
  transform: translateX(50%);
  margin-bottom: 0;
}

.notify-enter-active {
  max-height: 200px;
  opacity: 1;
  transform: translateX(0);
  margin-bottom: 15px;
  transition: max-height .65s ease-out, transform .5s, opacity .5s, margin-bottom .3s;
}

.notify-exit {
  max-height: 200px;
  opacity: 1;
  transform: translateX(0);
  margin-bottom: 15px;
}

.notify-exit-active {
  margin-bottom: 0;
  max-height: 0;
  opacity: 0;
  transform: translateX(50%);
  border: none;
  transition: max-height .65s ease-out, transform .5s, opacity .5s, margin-bottom .3s;
}
