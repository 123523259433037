.channels {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: inherit;
  position: relative;
}

.channels__header {
  padding: 0 0 15px;
}

.channels__controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.channels__management {
  display: inline-block;
  white-space: nowrap;
  margin: auto;
  min-height: 45px;
  padding: 10px 16px;
  font-size: 16px;
}

.channels__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 200px;
}

.channels__list {
  margin: 0;
  padding: 0 0 19px 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 19px;
}

.channels__empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 20px;
  z-index: 1;
}

.channels__empty_text {
  margin: 0;
  text-align: center;
  color: var(--text-color-secondary, var(--brand-color-6));
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  transition: color 0.3s ease;
}

:root[data-theme="light"] .channels__empty_text {
  color: var(--text-color-secondary-light, #666666);
}

:root[data-theme="dark"] .channels__empty_text {
  color: var(--text-color-secondary-dark, #999999);
}

@media (max-width: 768px) {
  .channels__management {
    padding: 8px 14px;
    margin: 24px 0 10px;
    min-height: 45px;
  }
  
  .channels__list {
    gap: 15px;
    margin-bottom: 125px;
  }

  .channels__empty_text {
    font-size: 14px;
  }
}