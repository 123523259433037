.reset {
    display: grid;
    row-gap: 12px;
}

.reset .reset__popover {
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 78px);
    color: var(--error-color);
    white-space: nowrap;
}

.reset__text_center {
    text-align: center;
    margin-bottom: 14px;
    color: var(--brand-color-1);
}

@media (max-width: 1080px) {
    .reset .reset__popover {
        position: initial;
        top: auto;
        padding-left: 5px;
        transform: none;
        background-color: transparent;
        box-shadow: none;
        color: var(--error-color);
        white-space: nowrap;
    }
}