.info {
  color: var(--brand-color-1);
  display: flex;
  flex-flow: column;
  row-gap: 25px;
  transition: color 0.3s;
}

.info_row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.info__tariff {
  display: flex;
  justify-content: space-between;
  max-width: 230px;
}

.info__field-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: var(--brand-color-1);
  transition: color 0.3s;
}

.info_field-title-with-image {
  display: flex;
  align-items: center;
  gap: 5px;
}

.info__referrals-count-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 230px;
  padding-top: 1px;
  align-items: center;
}

.info__image {
  margin-top: 2px;
  height: 18px;
  width: 20px;
  border-radius: 100%;
}

.info__addition-info {
  min-width: 120px;
}

@media (max-width: 768px) {
  .info {
    display: flex;
    gap: 18px;
  }

  .info__field-title {
    font-weight: 400;
    color: var(--brand-color-9);
    text-wrap: nowrap;
  }

  .info__tariff {
    width: 100%;
  }

  .info__tariff a {
    text-decoration: underline;
  }

  .info_row {
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  .info_row span:last-child,
  .info__referrals-count-value {
    margin-left: 5px;
    font-weight: 600;
    color: var(--brand-color-5);
  }

  .info_row:first-child {
    margin-top: 20px;
  }
}
