/* Centralize content on the page */
.layout {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Error block in column */
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--brand-color-1);
}

/* 
  Example: 
    error__text_message: Forbidden
    error__text_description: Недостаточно прав для просмотра
*/
.error__text {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error__text_message { 
  font-size: 24px;
  font-weight: 600;
}
.error__text_description {
  font-size: 16px;
  margin-top: 28px;
  margin-bottom: 28px;
}


/* Return to main page button */
.error__link {
  text-decoration: none;
  outline: none;
  min-width: 248px; 
}

@media (max-width: 768px) {
  .error svg {
      width: 100%;
  }
}