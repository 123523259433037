.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: var(--background-color-2);
  padding: 20px;
  width: 90%;
  max-width: 500px;
  height: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}

.title-container {
  align-self: center;
}

.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

.link-style {
  color: rgb(71, 71, 249);
  text-decoration: underline;
  display: block; /* Убедитесь, что ссылки отображаются блочно, каждая на новой строке */
  text-align: center;
  margin-top: 10px; /* Добавляем небольшой отступ сверху для лучшей читаемости */
}

.button-container {
  align-self: center;
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
  .modal-content {
      width: 95%; /* Увеличиваем ширину на очень маленьких экранах */
      padding: 10px; /* Уменьшаем паддинг для экономии пространства */
  }

  .text-container, .title-container, .button-container {
      padding: 5px; /* Уменьшаем паддинги внутри контейнеров */
  }

  .link-style {
      font-size: 14px; /* Уменьшаем размер шрифта ссылок */
  }
}
