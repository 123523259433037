.container {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.container-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.button-on,
.button-off,
.container .button-on-light,
.container .button-off-light,
.container .button-on-dark,
.container .button-off-dark {
    font-family: 'Futura PT', sans-serif;
    font-style: normal;
    border: 0 solid transparent;
    padding: 0;
    background-color: transparent;
    font-weight: 1000;
    font-size: 0.9rem;
    line-height: 21px;
    display: flex;
    align-items: center;
    margin-top: 3.5px;
    cursor: pointer;
}

.container .button-on-light,
.container .button-off-light {
    color: #000000;
}

.container .button-on-dark,
.container .button-off-dark {
    color: white;
}

.button-on-light:hover:not(:disabled),
.button-on-dark:hover:not(:disabled) {
    color: rgb(0, 173, 112);
    transition: 0.3s;
}

.button-off-light:hover:not(:disabled),
.button-off-dark:hover:not(:disabled) {
    color: rgb(173, 29, 0);
    transition: 0.3s;
}

.button-on-light:disabled,
.button-off-light:disabled,
.button-on-dark:disabled,
.button-off-dark:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.container .text-light,
.container .text-dark {
    display: flex;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 1.05rem;
    line-height: 21px;
    margin: 0 1rem 0 0;
    padding-bottom: 2px;
    margin-left: 30px;
}

.container .text-light {
    color: #000000;
}

.container .text-dark {
    color: white;
}

.container .distance-between-buttons-light,
.container .distance-between-buttons-dark {
    height: 1rem;
    margin: 0 0.3rem 0.3rem;
    margin-top: 2px;
}

.container .distance-between-buttons-light {
    color: #000000;
}

.container .distance-between-buttons-dark {
    color: white;
}