.widget {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.widget > div {
  min-height: 100vh;
  width: 100%;
}