/* Color picker component styles */
.rcp {
  background-color: var(--background-color-2) !important;
  border: 1px solid var(--border-color-1);
}

.rcp-body {
  padding: 15px 19px !important;
}

.rcp-fields {
  grid-template-columns: repeat(2, 1fr) !important;
}

.rcp-fields-element-input {
  border: 1px solid !important;
  color: var(--brand-color-1) !important;
  border-color: var(--border-color-1) !important;
}