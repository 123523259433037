.notify {
  width: 100%;
  background: var(--background-color-2);
  border: 1px solid var(--border-color-9);
  border-radius: 6px;
  overflow: hidden;
}

.notify__wrapper {
  padding: 15px 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  color: var(--brand-color-1);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  transition: max-height 0.3s;
}

/* Notify icons */
.notify__wrapper svg {
  margin: 3px 16px 0 0;
  max-width: 19px;
  min-width: 19px;
  height: 19px;
}

/* Notify text */
.notify__wrapper p {
  margin: 0 20px 0 0;
  max-width: 360px;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.notify__close {
  margin-left: auto;
  cursor: pointer;
  opacity: 0.9;
  transition: opacity 0.3s;
}

.notify__close:hover {
  opacity: 1;
}

.notify__bar {
  width: 100%;
  height: 2px;
  background: #ff65dd;
  animation-name: timebar;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes timebar {
  from {
    width: 100%;
    background: #ff65dd;
  }

  to {
    width: 0%;
    background: #2c98fc;
  }
}
