.fade-in-enter {
  opacity: .5;
  transform: translateY(-3vh) scale(0.9);
}

.fade-in-enter-active {
  opacity: 1;
  transform: translateY(0) scale(1);
  transition: opacity .2s, transform .2s;
}

.fade-in-exit {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.fade-in-exit-active {
  opacity: 0;
  transform: translateY(-3vh) scale(0.9);
  transition: opacity .2s, transform .2s;
}
