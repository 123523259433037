.step {
  position: relative;
  flex: 1;
}

/* Step number block */
.step__number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: var(--background-color-6);
  color: var(--brand-color-11);
  font-weight: 700;
  font-size: 20px;
  border: 1px solid var(--border-color-10);
  border-radius: 100%;
}

.step__number_border::after {
  content: "";
  position: absolute;
  top: 19px;
  left: 40px;
  right: 0;
  background: var(--background-color-7);
  height: 2px;
}

/* Step text */
.step__text {
  max-width: 267px;
  margin: 20px 53px 0 0;
  color: var(--brand-color-1);
}

.step__text > h2 {
  margin: 0;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
}

.step__text > p {
  margin: 10px 0 0 0;

  color: #808080;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}

@media (max-width: 768px) {
  .step {
    flex: initial;
  }

  .step__text {
    max-width: 100%;
    margin: 20px 0 0 0;
  }

  .step__number_border::after {
    display: none;
  }
}
