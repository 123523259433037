.react-time-picker__clock {
    display: none !important;
}

.react-time-picker {
    width: 100%;
}

.react-time-picker__wrapper {
    border: 1px solid var(--border-color-6);
    border-radius: 8px;
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    transition: border .3s;
}

.react-time-picker__inputGroup {
    color: var(--brand-color-1);
    box-sizing: border-box;
    height: inherit;
    border: none;
    background-color: transparent;
    padding: 13px 16px;
    font-family: inherit;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    border-radius: inherit;
    width: 100%;
    outline: none;
    transition: color .3s;
}

.react-time-picker__inputGroup input {
    color: var(--brand-color-1);
    transition: color .3s;
}