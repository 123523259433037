.mobile-nav {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px 8px 0px 0px;

  background: var(--background-color-2);

  z-index: 4;
}