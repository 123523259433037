.message {
  --block-spacing: 0.65em;

  display: flex;
  align-items: center;
  font-size: 13px;
}

.message_technical {
  background: var(--gradient-background);
  border-radius: 5px;
  padding: 16px 10px;
  border: 1px solid var(--border-color-9)
}

.message_technical .message__platform {
  margin-top: -6px;
}
.message_technical .message__time {
  color: var(--brand-color-1);
}
.message_technical .message__content {
  color: var(--brand-color-1);
}

.message__platform {
  align-self: flex-start;
  margin-right: var(--block-spacing);
  min-width: 2.8em;
  max-width: 2.8em;
  height: 2.8em;
}

.message__info {
  width: 100%;
}

.message__info-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--block-spacing);
}

.message__name {
  color: var(--brand-color-5);
  padding: 0;
  margin: 0;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message__time {
  color: #98A9BC;
  margin: 0;
}

.message__content {
  color: var(--brand-color-6);
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  word-break: break-word;
}