.notification {
  display: flex;
  padding: 20px;
}

.notification:not(:last-child) {
  border-bottom: 1px solid var(--border-color-1);
}

.notification__icon {
  flex-shrink: 0;
  margin: 2px 0 0 0;
  width: 14px;
  height: 14px;
}

.notification__content {
  margin: 0 0 0 10px;
}

.notification__title {
  margin: 0;
  position: relative;
  color: var(--brand-color-7);
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.notification__text {
  margin: 3px 0 0 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@media (max-width: 768px) {
  .notification {
    border-bottom: 1px solid var(--border-color-1);
  }
}
