.avatar-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar-block-empty {
  padding: 10%;
}

.avatar-block__img {
  border-radius: 50%;
  background-color: #c3c4ff;
  width: 100%;
  max-width: 175px;
  aspect-ratio: 1 / 1;
  box-sizing: border-box;
}

.avatar-block__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}


/* Upload block */
.avatar-block__upload {
  position: relative;
  margin-top: 11px;
}

.avatar-block__upload input {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: 0;
}

.avatar-block__email {
  margin: 15px 0 4px 0;
  color: var(--brand-color-1);
  font-weight: 700;
  text-align: center;
}

.avatar-block__upload-error {
  margin-top: 15px;
  color: red;
}

.avatar-block__hint {
  margin-top: 11px;
  color: var(--brand-color-1);
}

@media (max-width: 768px) {
  .avatar-block__img {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 152px;
  }

  .avatar-block__upload {
    width: 215px;
  }

  .avatar-block__img svg {
    width: 75%;
  }

}