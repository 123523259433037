.popup {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup__structure {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  max-height: 90vh;
  width: 100%;
}

.popup__header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 1em;
  border-bottom: 1px solid #e0e0e0;
}

.popup__title {
  font-weight: 800;
  font-size: 25px;
  line-height: 28px;
  color: var(--brand-color-5);
  transition: color 0.3s;
  width: 180px;
}

.popup__content {
  padding: 1em;
  width: 100%;
  height: 400px;
}

.button-group-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.button-group-container__tooltip {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.button {
  border: 3px solid var(--border-color-1);
  border-radius: 15px;
  cursor: pointer;
  padding: 0.5em 1em;
  transition: background 0.3s, border 0.3s;
}

.button_state_active {
  border-width: 2px;
  border-style: solid;
  background: var(--container-gradient);
  border: 3px solid var(--border-color-5);
  color: var(--brand-color-11);
}

.add-button {
  color: white;
  width: auto;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1em;
}

.footer__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.footer__button {
  width: auto;
}

.channel-settings {
  display: flex;
  gap: 20px;
  padding: 0;
  margin-top: 20px;
  justify-content: center;
}

.channel-settings__column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.actions__container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.actions__item {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0px 16px;
}

.actions__item_type_dangerous {
  color: #F75C5C;
}

.actions__text {
  margin: 10px 0px;
}

.actions__icon {
  width: 19px;
  height: 15px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.empty-state__text {
  margin-bottom: 15px;
  font-size: 16px;
  color: var(--text-color);
}

@media screen and (max-width: 768px) {
  .popup__structure {
    width: 100%;
    min-height: 100%;
    padding: 10px;
    margin: 0;
    padding-top: 0;
    padding-bottom: 10px;
  }

  .popup__header {
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
    padding: 12px;
  }

  .popup__title {
    width: 100%;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  .button-group-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    width: 100%;
    padding: 0 4px;
  }

  .button {
    width: 100%;
    min-height: 44px;
    font-size: 14px;
    border-width: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    margin: 0;
  }

  .button__text {
    font-size: 13px;
    line-height: 1.2;
  }

  .add-button {
    width: 100%;
    margin-bottom: 12px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    padding: 0;
  }

  .footer__pagination {
    flex: 0 1 auto;
    margin: 0 4px;
    font-size: 12px;
  }

  .footer__button {
    width: auto;
    white-space: nowrap;
    font-size: 12px;
    padding: 6px 10px;
    min-height: 32px;
  }

  .channel-settings {
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
  }

  .channel-settings__column {
    width: 100%;
    gap: 12px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }

  .actions__container {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }

  .actions__item {
    width: auto;
    justify-content: center;
    padding: 6px 3px;
    font-size: 12px;
    min-height: 32px;
  }

  .actions__text {
    margin: 0;
    font-size: 12px;
  }

  .actions__icon {
    width: 14px;
  }

  .empty-state {
    padding: 20px;
    gap: 12px;
  }

  .empty-state__text {
    margin-bottom: 12px;
    font-size: 14px;
  }
}