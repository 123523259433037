.captcha {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.captcha__logo {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

.captcha__icon {
  width: 24px;
  height: 24px;
}

.captcha__image {
  border-radius: 8px;
}

.captcha__form {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.captcha__input {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background: transparent;
  font-family: "Mulish";
  color: #fff;
  outline: none;
  border: 1px solid var(--border-color-6);
  border-radius: 8px;
}

.captcha__button {
  margin-top: 10px;
  width: 100%;
  height: 38px;
}

.captcha__button button {
  font-size: 13px;
  padding: 7px;
}