.popover-container {
    position: relative;
}

.popover-container__popover {
    position: absolute;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 42px 34px rgba(82, 67, 194, 0.3);
    border-radius: 16px;
    padding: 12.5px 28px 12.5px 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
}

.popover-container__popover:empty {
    display: none;
}