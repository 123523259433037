.password-field {
    position: relative;
    display: flex;
}

.password-field__icon {
    position: absolute;
    top: 0;
    right: 20px;
    border: none;
    padding: 0;
    width: 16px;
    height: 49px;
    background-color: transparent;
}

.password-field__icon_pointer {
    cursor: pointer;
}

.password-field .password-field__input {
    padding-right: 60px;
}