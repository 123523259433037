.platforms {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;

  margin-bottom: 18px;
}

@media (max-width: 440px) {
  .platforms {
    grid-template-columns: 1fr;
  }
}