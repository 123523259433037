.payments {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.payments__heading {
  color: var(--brand-color-1);
}

.payments__table {
  margin: 30px;
}

.payments__table-text-empty {
  font-family: 'Mulish';
  color: var(--brand-color-1);
  font-size: 20px;
}

.payments__pagination {
  margin-bottom: 50px;
}

/* * Table */
.table {

}

@media (max-width: 768px) {
  .payments {
    margin: 0;
    padding-bottom: 60px;
  }

  .payments > h1 {
    font-size: 24px;
  }

  .payments__table {
    width: 100%;
    margin: 0;
    padding: 24px 16px 0;
  }
}