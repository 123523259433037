.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 1.02571px;
  color: var(--brand-color-6);
  padding: 30px 60px 0;
  transition: color 0.3s;
}

.page {
  padding: 50px 60px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-row: repeat(2, 1fr);
  align-items: start;
  column-gap: 5%;
}

.page__section {
  display: flex;
  flex-flow: column;
  gap: 65px;
}

.popup__exclamation-mark.exclamation-mark {
  margin-bottom: 53px;
}

.description {
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: var(--brand-color-1);
}

/* Logout text */
.user-logout {
  width: 100%;
  text-align: left;
  margin: 0;
  color: var(--error-color);
}

@media (max-width: 768px) {
  .page {
    padding: 30px 27px 150px;

    display: flex;
    flex-flow: column;
    align-items: center;

    gap: 40px;
  }

  .user-info {
    display: flex;
    flex-flow: column;

    gap: 15px;
  }

  .user-info > button {
    width: 215px;
  }

  .page__section {
    margin-top: 20px;
  }
}