.select {
  position: relative;
}

.select__field {
  position: relative;
}

.select__field-input {
  padding: 13px 44px 13px 16px;
}

.select__arrow {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 13px;
  right: 13px;
  transition: transform 0.3s;
}

.select__arrow_rotated {
  transform: rotate(180deg);
}

.select__arrow_disabled {
  opacity: 20%;
}

.select__options {
  z-index: 1;
  width: 100%;
  background: var(--background-color-2);
  border: 1px solid var(--border-color-1);
  border-radius: 8px;
  overflow-y: auto;
  top: 100%;
  margin-top: 8px;
}

.select__options_position_top {
  top: auto;
  margin-bottom: 8px;
  margin-top: 0;
}

.select__options-container {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.select__option {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: var(--background-color-2);
  position: relative;
  transition: background-color 0.2s;
}

.select__option:hover {
  background-color: var(--background-color-3);
}

.select__option input {
  display: none;
}

.select__option-image {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  overflow: hidden;
  border-radius: 5px;
  flex-shrink: 0;
}

.select__option-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.select__option p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select__option_selected {
  color: var(--focus-input);
  background-color: var(--background-color-4);
}

.select__option:not(:last-child) {
  border-bottom: 1px solid var(--border-color-1);
}