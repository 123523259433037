.stream {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid var(--border-color-1);
  transition: border .3s;
}

.stream_selected {
  border: 1px solid #b969ff; 
}

.stream:hover {
  border: 1px solid #b969ff;
}

.stream__image {
  width: 34px;
  height: 34px;
  border-radius: 7px;
  object-fit: cover;
  flex-shrink: 0;
}

.stream__info {
  margin-left: 15px;
  display: flex;
  flex-flow: column;
}

.stream__info p {
  margin: 0;
}

.stream__name {
  color: var(--brand-color-7);
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 390px;
  max-width: 100%;
}

.stream__role {
  color: #A4A4A4;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

@media (max-width: 600px) {
  .stream__name {
    font-size: 14px;
    width: 250px;
  }
}