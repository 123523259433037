.message {
  display: flex;
  animation: messageFade .5s forwards;
}

@keyframes messageFade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Platform icon */
.message__platform {
  display: flex;
  width: 100%;
  margin-right: 8px;
}

/* Username, message text wrapper */
.message__content {
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 6px;

  word-break: break-all;

  font-size: 13px;
  line-height: 18px;
}

.message__username {
  font-weight: 700;
}

.message__text {
  font-weight: 500;
  word-break: break-word;
}