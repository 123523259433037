.slider {
  position: relative;
}

.slider input {
  height: 22px;
  position: relative;
  z-index: 2;
  width: 100%;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Remove default tumb */
.slider input::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/* Styles for background track bar */
.slider input::-webkit-slider-runnable-track {
  background: var(--main-gradient);
  border-radius: 50px;
  height: 2px;
  margin: 0 6px;
}

.slider input::-moz-range-track {
  background: var(--main-gradient);
  border-radius: 50px;
  height: 2px;
  margin: 0 6px;
}

/* Styles for tumb */
.slider input::-webkit-slider-thumb {
  cursor: pointer;
  height: 10px;
  width: 10px;
  background: #ffffff;
  border-radius: 50px;
  border: 1px solid #697A8D;
  margin-top: -4px;
}

.slider input::-moz-range-thumb {
  cursor: pointer;
  height: 10px;
  width: 10px;
  background: #ffffff;
  border-radius: 50px;
  border: 1px solid #697A8D;
  margin-top: -4px;
}

/* Styles for range tumb value */
.slider__range-tumb {
  position: absolute;
  top: 15px;
  left: 0;
  
  user-select: none;

  color: var(--brand-color-3);
  font-weight: 800;
  font-size: 10px;
  line-height: 21px;
}


/* Fake tickmarks */
.slider__tickmarks {
  z-index: 0;

  width: 100%;

  position: absolute;
  top: -11px;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.slider__tickmarks option {
  text-align: initial;
  width: 24px;
  height: 21px;
  position: relative;
  padding: 0;
  user-select: none;
  color: var(--brand-color-3);
  font-weight: 600;
  font-size: 10px;
  line-height: 21px;
}

.slider__tickmarks option:not(:first-child, :last-child) {
  text-align: center;
} 

.slider__tickmarks option:not(:first-child, :last-child)::after {
  content: "";
  background: url("./assets/tickmark.svg") no-repeat;
  width: 2px;
  height: 6px;
  position: absolute;
  bottom: -4px;
  left: 52%;
}