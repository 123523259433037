.stream {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 10px 0 10px;
  border-radius: 8px;
  border: 1px solid var(--border-color-1);
  flex-direction: column;
  transition: border .3s;
}

.stream__container {
  align-items: center;
  border: 1px solid #0000;
  border-radius: 10px;
  display: flex;
  padding: 5px;
  transition: border .3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  width: 100%;
}

.stream__events-dropdown-container {
  width: 100%;
  margin: 10px 10px 0 10px;
  border-top: 1px solid var(--border-color-1);
}

.stream_selected {
  border: 1px solid #b969ff;
}

.stream__container:hover:not(.stream_selected) {
  border: 1px solid var(--border-color-1);
}

.stream__image {
  width: 34px;
  height: 34px;
  border-radius: 7px;
  object-fit: cover;
}

.stream__info {
  margin-left: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.stream__role {
  color: #A4A4A4;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.stream__img {
  width: 50px;
  height: 50px;
  border-radius: 7px;
}

@media (max-width: 600px) {
  .stream__name {
    font-size: 14px;
  }
}