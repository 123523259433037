.card {
  background: var(--background-color-2);
  border-radius: 10px;
  padding: 10px 20px;
  transition: background .3s;
}


/* 
  ==============================
  * Название продукта с логотипом
  =========+====================
*/
.card__heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__heading_title {
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
  color: var(--brand-color-1);
  transition: color .3s;
}


/* 
  ===================
  * Описание продукта
  =========+=========
*/
.card__description {
  font-size: 12px;
  margin: 5px 0 15px 0;
  word-break: break-word;
  color: var(--brand-color-1);
  transition: color .3s;
}