.user {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  gap: 10px;
}

/* Avatar */
.user__avatar {
  position: relative;
  background-color: #c3c4ff;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  box-sizing: border-box;
}

.user__avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.user__avatar_empty {
  padding: 10px;
}

/* Info */
.user__info {
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 1px;
}

.user__name {
  margin: 0;
  color: var(--brand-color-7);
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  transition: color 0.3s;
}

.user__name_active {
  color: #d273fa;
}

.user__tariff {
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #979797;
}

/* Menu */
.user__menu {
  z-index: 101;
  min-width: 215px;
  position: absolute;
  top: 58px;
  right: 0;
  will-change: transform;
}

@media (max-width: 767px) {
  .user__menu {
    top: 55px;
  }

  .user__info {
    display: none;
  }
}
