.mark {
    width: 47px;
    height: 47px;
    border: 4px solid var(--mark);
    color: var(--mark);
    box-sizing: border-box;
    padding: 8px;
    border-radius: 50%;
    transition: border .3s, color .3s;
}

@media screen and (max-width: 768px) {
    .mark {
        width: 32px;
        min-width: 32px;
        height: 32px;
        padding: 3px;
        border-width: 3px;
    }
}