.notifications {
  position: relative;
}

.notifications__icon {
  position: relative;
  width: 22px;
  height: 22px;
  cursor: pointer;
  transition: color 0.3s;
}

.notifications__icon-point {
  position: absolute;
  width: 6px;
  height: 6px;
  right: 1px;
  top: -3px;
  border-radius: 100px;
  background: #d273fa;
  animation: pulsation 1.8s infinite;
}

.notifications__icon_active {
  color: #d273fa;
}

.notifications__list {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 10;
  will-change: transform;
}

@keyframes pulsation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .notifications__list {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
