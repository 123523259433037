.empty {
  max-width: 300px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  color: var(--border-color-7);
}

.empty h3 {
  font-weight: 600;
  font-size: 64px;
  line-height: 99px;
  margin: 0;
  letter-spacing: 0.769286px;
  color: var(--brand-color-1);
  margin-bottom: var(--indent);
  transition: color .3s;
}

.empty p {
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  margin: 50px;
  letter-spacing: 0.769286px;
  color: var(--brand-color-1);
  transition: color .3s;
}