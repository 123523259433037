/* Custom padding for popup */
.popup > div > div {
  padding: 0;
}

/* div = src/components/Popup */
.popup > div {
  padding: 0;
}

.settings__section {
  margin: 30px 0;
}

.settings__section:first-child {
  margin-top: 0;
}

/* Widget preview */
.preview {
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  border-bottom: 1px solid var(--border-color-1);
}

/* Titles */
.settings__title {
  margin: 0 0 10px 0;
  
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
  color: var(--brand-color-1)
}

.settings__title_scale {
  margin: 0 0 15px 0;
}

.settings__section_row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.settings__section_row .settings__title {
  text-align: center;
}

/* Copy field */
.settings__copy > div {
  grid-template-rows: 48px;
}

.settings__copy input {
  font-size: 12px;
}

.settings__copy button {
  padding: 12px;
}

/* Colors blocks */
.settings__block {
  display: flex;
  flex-flow: column;
  align-items: center;
}

@media (max-width: 768px) {
  .preview {
    border-radius: 0;
  }

  .settings__section_row {
    align-items: flex-start;
    flex-flow: column;
    gap: 15px;
  }

  .settings__block {
    width: 100%;
    align-items: flex-start;
  }

  .settings__picker {
    width: 100%;
  }

  .settings__section:first-child {
    margin-top: 30px;
  }
}
