.login {
    display: grid;
    row-gap: 12px;
}

.login .login__popover { /* Повышенный приоритет чтобы не влиял порядок подключения стилей при сборке */
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 78px);
    color: var(--error-color);
    white-space: nowrap;
    background: var(--background-color-1)
}

.login__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 63px;
    color: var(--brand-color-1);
}

@media (max-width: 768px) {
    .login .login__popover {
        position: relative;
        transform: none;
        top: initial;
        left: initial;
        background: transparent;
        box-shadow: none;
        padding: 10px 5px;
    }
}