/* Popup subtitle */
.popup__subtitle {
  color: var(--brand-color-1);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.popup__subtitle > a {
  color: var(--brand-color-5);
}

/* Popup sections with main info */
.popup__section {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: var(--brand-color-1);
}

.popup__section:not(:first-child) {
  margin: 25px 0;
}

.popup__section p {
  margin: 0;
}

.popup__section > div {
  display: flex;
  align-items: center;
  margin-left: 11px;
}

.popup__section > div > img {
  width: 20px;
  margin-right: 6px;
}

.popup__section input {
  background: transparent;
  border: none;
  outline: none;
  padding-bottom: 3px;
  font-family: "Mulish";
  font-size: 14px;
  text-align: center;
  color: #a7a7a7;
  border-bottom: 1px solid #d6d6d6;
}

.popup__section input::-webkit-inner-spin-button {
  display: none;
}

.popup__section input {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

/* Input error */
.popup__error {
  color: var(--error-color);
  text-align: center;
  font-size: 14px;
}

@media (min-width: 768px) {
  .popup > div > div {
    max-width: 380px;
    padding: 15px;
  }
}
