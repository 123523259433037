.broadcast-edit__form {
    display: grid;
    row-gap: 24px;
    overflow: hidden;
}

.broadcast-edit__field-section {
    display: flex;
    gap: 20px;
}
