.button-group {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 17px;
}

.button-group li {
    flex: 1;
}
