.start-broadcasting {
    --indent: 33px;

    background: var(--gradient-background);
    border: 1px solid var(--border-color-1);
    border-radius: 28px;
    padding: 30px 34px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    transition: background .3s, border .3s;
}

.start-broadcasting__title {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.769286px;
    color: var(--brand-color-1);
    margin-bottom: var(--indent);
    transition: color .3s;
}

.start-broadcasting__input-field {
    color: var(--brand-color-1);
    background-color: var(--background-color-2);
    transition: color .3s, background-color .3s;
}

.start-broadcasting .start-broadcasting__button {
    font-weight: 700;
    padding: 14px 0;
    margin-top: var(--indent);
}

.start-broadcasting__error {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #EC26B5;
    margin-top: var(--indent);
}

.start-broadcasting__error:empty {
    display: none;
}

@media (max-width: 768px) {
    .start-broadcasting {
        width: 100%;
    }
}