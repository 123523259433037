.popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: none;
    z-index: 9;
    justify-content: center;
    align-items: center;
}

.popup_show {
    display: flex;
}

.popup__body {
    max-height: 100vh;
    width: 100%;
    background: var(--background-color-2);
    border-radius: 16px;
    transition: background .3s, max-width .3s;
    box-sizing: initial;
    overflow: auto;
}

@media (max-width: 768px) {
    .popup {
        width: 100vw;
        height: 100vh;
        width: 100vw;
    }
}

@media screen and (max-width: 768px) {
    .popup__body {
        max-width: 100%;
        height: 100%;
        overflow-y: scroll;
        border-radius: 0px;
    }
}

@media screen and (max-width: 440px) {
    .popup {
        height: 100%;
        width: 100vw;
    }
    .popup__body {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        border-radius: 0px;
    }
}