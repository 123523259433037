.greeting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 35px;
    box-sizing: border-box;
    border: 1px solid var(--border-color-1);
    background-color: var(--greeting-color);
    border-radius: 28px;
    transition: border .3s, background-color .3s;
}

.greeting__title {
    color: var(--brand-color-2);
    letter-spacing: 1.15714px;
    font-weight: 800;
    font-size: 30px;
    line-height: 32px;
    transition: color .3s;
}

.greeting__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.598334px;
    color: var(--brand-color-2);
    margin-top: 12px;
    transition: color .3s;
}

.greeting__subtitle-link {
    color: inherit;
}

.tariff-info {
    display: grid;
    grid-template-columns: 50px 1fr;
    column-gap: 16px;
    row-gap: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.598334px;
    color: var(--brand-color-1);
    margin-top: 26px;
    transition: color .3s;
}

.tariff-info__value {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--brand-color-1);
    transition: color .3s;
}


@media (max-width: 768px) {
    .greeting {
        width: 100%;
        padding: 32px;
    }

    .greeting__title {
        font-size: 24px;
    }

    .greeting__subtitle {
        font-size: 14px;
    }
}
