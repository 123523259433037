.chat-widget {
  overflow: hidden;
}

.chat-widget-preview {
  background: url("./assets/bg.png");
}

.chat-widget__wrapper {
  height: 100%;

  padding: 25px 20px;

  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  gap: 18px;

  border-radius: inherit;
}