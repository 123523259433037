.platform {
  /* Reset */
  cursor: pointer;
  border: 1px solid var(--border-color-1);
  outline: none;
  color: inherit;
  
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  width: 100%;

  background-image: var(--brand-gradient-2);
  background-position: left;
  background-size: 300%;
  transition:
    background-position 0.3s linear,
    border 0.3s,
    color 0.3s;
}

.platform_disabled {
  cursor: default;
  opacity: 0.4;
}
.platform:hover {
  background-position: right;
}
.platform_disabled:hover {
  background: var(--background-color-2);
}

.platform__icon {
  width: 35px;
  height: 35px;
  margin-right: 15px;
}

.platform__name {
  /* Reset */
  padding: 0;
  margin: 0;
  font-family: 'Mulish';
  font-size: 16px;
  font-weight: 600;

  margin: 0 auto;
}