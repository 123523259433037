.copy-field {
  display: grid;
  grid-template-columns: 1fr 48px;
  grid-template-rows: 48px;
}
.copy-field__reload-button {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 48px;
  justify-content: center;
}
.copy-field__button {
  border: none;
  border-radius: 0px 8px 8px 0px;
  padding: 14px;
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s;
}
.reload-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color-6);
  padding: 10px;
  cursor: pointer;
  background: var(--background-color-2);
}
.reload-button svg {
  width: 24px;
  height: 24px;
}
.reload-button--is-rotating svg {
  animation: rotate-left-click 1s linear infinite;
  animation-iteration-count: 1;
  animation-duration: 1s;
}
@keyframes rotate-left-click {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.copy-field-buttons {
  display: grid;
  grid-template-columns: 48px 48px;
  grid-template-rows: 48px;
}
.copy-field,
.copy-field__input,
.copy-field_reload_button .copy-field__input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
}
.copy-field_purple {
  background: rgba(43, 75, 242, 0.06);
  border-right-color: #9250D3;
}
.copy-field_purple .copy-field__button,
.copy-field_purple .reload-button {
  color: #fff;
  background-color: var(--brand-color-5);
}
.copy-field_gray {
  border-radius: 8px;
  background: var(--background-color-2);
}
.copy-field_gray input {
  color: var(--brand-color-1);
}
.copy-field_gray .copy-field__button,
.copy-field_gray .reload-button {
  color: var(--border-color-6);
  background-color: transparent;
  border: 1px solid var(--border-color-6);
  border-left: none;
}