@import-normalize; /* bring in normalize.css styles */
@import url("./styles/theme.css");
@import url("./styles/react-transition-group.animations.css");

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish-Regular.woff2") format("woff2"),
        url("fonts/Mulish-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish-Medium.woff2") format("woff2"),
        url("fonts/Mulish-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish-SemiBold.woff2") format("woff2"),
        url("fonts/Mulish-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish-Bold.woff2") format("woff2"),
        url("fonts/Mulish-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("fonts/Mulish-ExtraBold.woff2") format("woff2"),
        url("fonts/Mulish-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Noto Color Emoji";
    src: url("fonts/NotoColorEmoji-Regular.ttf") format("truetype");
    font-style: normal;
}

* {
    box-sizing: border-box;
}

#root {
    min-height: 100vh;
    background-color: var(--background-color-1);
    transition: background-color .3s;
    --focus-shadow: 0 0 0 1px rgba(146, 80, 211, 0.16);
}

body {  
    margin: 0;
    font-family: 'Mulish', sans-serif, 'Noto Color Emoji' !important;
    font-weight: 400;
    overflow-x: hidden;
    overflow-y: auto;
}

body.disable-scroll {
    overflow-y: hidden;
    margin-right: var(--scroll-width);
}

#root *::-webkit-scrollbar {
    width: 9px; /* ширина scrollbar */
    background-clip: padding-box;
}

#root *::-webkit-scrollbar-thumb {
    background: var(--brand-color-5);
    border: 3px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
}