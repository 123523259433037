.switcher {
  width: 22px;
  height: 22px;
  cursor: pointer;
  transition: color 0.3s;
}

.switcher:hover {
  color: #d273fa;
}
