.broadcast-delete__exclamation-mark {
    margin-bottom: 20px;
}

.broadcast-delete__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: var(--brand-color-1);
    margin-bottom: 48px;
    transition: color .3s;
    word-break: break-all;
}

@media screen and (max-width: 768px) {
    .broadcast-delete__exclamation-mark {
        margin-right: 20px;
        margin-bottom: 0;
    }
}