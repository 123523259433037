.mailing {
  max-width: 350px;
}

.mailing__primary {
  margin: 0;

  color: var(--brand-color-1);
  line-height: 14px;
}

.mailing__secondary {
  margin-top: 7px;

  color: #888888;
  line-height: 18px;
}
