.instruction {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.instruction__icon {
  min-width: 30px;
  max-width: 40px;
  height: 30px;
  margin-right: 10px;
  flex-shrink: 0;
}

.instruction__text {
  margin: 0;
  font-size: 16px;
  line-height: 21px;
}

.instruction__link {
  margin-left: 4px;
  text-decoration: none;
  color: var(--brand-color-5);
  font-weight: 700;
}