.channel {
  position: relative;
  display: grid;
  grid-template-columns: 35px 1fr 110px;
  align-items: center;
  border: 1px solid var(--border-color-1);
  border-radius: 8px;
  padding: 10px 20px;
  background-image: var(--brand-gradient-2);
  background-position: left;
  background-size: 300%;
  transition: background-position 0.3s linear, border 0.3s, color 0.3s;
}

.channel_with-checkbox {
  grid-template-columns: 35px 35px 1fr 110px;
}

.channel__checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.channel__checkbox input {
  cursor: pointer;
}

/* Error state */
.channel_error {
  border: 1px solid #F65C5C;
}

.channel_error .dropdown {
  z-index: 6;
}

.channel_error .channel__buttons-edit {
  z-index: 4;
}

.channel_error .button-edit__dot {
  background: #F65C5C;
}

.channel__error {
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: absolute;
  background: var(--background-color-9);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  color: #F65C5C;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
}

.channel__error svg {
  width: 22px;
  height: 22px;
}

.channel_error .dropdown {
  z-index: 6;
}

.channel_error .channel__buttons-edit {
  z-index: 4;
}

.channel_error .button-edit__dot {
  background: #F65C5C;
}

.channel__error {
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: absolute;
  background: var(--background-color-9);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  color: #F65C5C;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
}

.channel__error svg {
  width: 22px;
  height: 22px;
}

.channel:hover {
  background-position: right;
}

.channel__platform {
  width: 35px;
  height: 35px;
}

.channel__name {
  padding-left: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  color: var(--brand-color-1);
  transition: color .3s;
}

.channel__buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 15px;
}

/* Checkbox Style */
.channel__checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.channel__checkbox input {
  cursor: pointer;
}

/* Edit button */
.button-edit {
  /* Reset */
  cursor: pointer;
  background: none;
  outline: none;
  border: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.button-edit_active > .button-edit__dot,
.button-edit:hover > .button-edit__dot {
  background: var(--brand-color-5);
}

.button-edit__dot {
  width: 3px;
  height: 3px;
  background: var(--switch-background);
  border-radius: 100%;
}

/* Dropdown */
.dropdown {
  min-height: max-content;
  z-index: 5;
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.14);
  border: 1px solid var(--border-color-1);
}

.dropdown__item {
  /* Reset */
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-family: 'Mulish';
  font-size: 13px;
  color: inherit;

  padding: 2px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--background-color-2);
  transition: background .3s;
}

.dropdown__item:hover,
.dropdown__item:focus {
  background: var(--border-color-1);
}

.dropdown__item_dangerous {
  color: #F75C5C;
}

.dropdown__item+.dropdown__item {
  border-top: 1px solid var(--border-color-1);
}

.dropdown__item-icon {
  width: 19px;
  height: 15px;
  margin-right: 6px;
}

/* Refresh button */
.channel_refresh-button {
  background: transparent;
  border: 1px solid #F65C5C;
  color: #F65C5C;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 100%;
  white-space: nowrap;
  cursor: pointer;
  margin-right: 30px;
}

.channel_refresh-button:hover {
  background: rgba(246, 92, 92, 0.1);
}