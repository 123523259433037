.tabs__titles {
  display: flex;
  justify-content: center;
  
  padding: 0;
  margin: 0;

  list-style: none;
}

@media (max-width: 768px) {
  .tabs {
    width: 100%;
  }
}