.button {
  /* Reset button */
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  border: 2px solid var(--brand-color-5);
  border-radius: 50%;
  padding: 10px;
  background-color: var(--date-picker-background);
  min-width: 38px;
  max-width: 38px;
  height: 38px;
  transition: background-color 0.3s;
}

.button > svg {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-47%, -42%);
  stroke: var(--brand-color-5);
  transition: stroke .3s;
}

/* Hover */

.button:hover,
.button:focus {
  background-color: var(--brand-color-5);
}

.button:hover > svg { stroke: var(--date-picker-background); }
.button:focus > svg { stroke: var(--date-picker-background); }


.button_prevented-pointer {
  /* 
    На мобильных устройствах при нажатии на кнопку подсвечиваются её физические очертания. 
    Происходит это из-за свойства { cursor: pointer }. Но поскольку на мобилках нет курсоров,
    проще всего просто поменять курсор на дефолтный.
  */
  cursor: default;
}