.form__section {
  display: flex;
  flex-flow: column;
}

.form__heading {
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 10px 0;
  color: var(--brand-color-1);
}

.form__buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.form__latency {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  font-size: 13px;
}

.form__latency_disabled {
  opacity: .6;
  pointer-events: none;
}

.form__latency-button {
  font-family: "Mulish";
  cursor: pointer;
  color: var(--brand-color-7);
  background: transparent;
  padding: 5px 15px;
  border: 1px solid var(--border-color-6);
  border-radius: 12px;
  transition: background .3s, color .3s;
}

.form__latency-button_active {
  color: #fff;
  background: var(--brand-color-5);
}

.form__latency-button:hover {
  color: #fff;
  background: var(--brand-color-5);
}
