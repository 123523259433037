.tooltip {
  position: relative;
  margin-top: 3px;
}

/* Tooltip positions */
.tooltip__wrapper.top-center {
  left: 50%;
  transform: translate(-50%, 0%);
}

.tooltip__wrapper.top-left {
  right: 0px;
}

.tooltip__wrapper.top-right {
  left: 0px;
}

.tooltip__wrapper.bottom-center {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  height: max-content;
}

.tooltip__wrapper.bottom-right {
  left: 90%;
  top: 100%;
  height: max-content;
  /* transform: translate(100%, 0); */
}
.tooltip__wrapper.bottom-left{
  right: 90%;
  top: 100%;
  height: max-content;
}

/* Tooltip block */
.tooltip__wrapper {
  z-index: 10;

  width: 300px;

  position: absolute;
  bottom: 33px;

  padding: 5px 9px;

  background: var(--background-color-2);
  border: 1px solid var(--brand-color-5);
  border-radius: 5px;

  color: var(--brand-color-5);

  visibility: hidden;
  transition: visibility .3s;

  will-change: visibility;
}

.tooltip__text {
  margin: 0;
  padding: 0;

  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}

.tooltip-hidden {
  visibility: hidden;
  opacity: 0;
}

.tooltip-visible {
  visibility: visible;
  opacity: 1;
}


@media (max-width: 768px) {
  .tooltip__wrapper {
    top: 33px;
    bottom: initial;
  }

  .tooltip__wrapper.top-center {
    left: 50%;
    transform: translate(-50%, 0%);
  }
  
  .tooltip__wrapper.top-left {
    right: 0px;
  }
  
  .tooltip__wrapper.top-right {
    left: 0px;
  }  
}