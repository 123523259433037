.header {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  padding: 18px 70px 0;
  margin-bottom: 15px;
}

/* Mobile back btn */
.header__mobile-back {
  display: none;
  align-items: center;
  color: var(--brand-color-7);
  text-decoration: none;
}

.header__back-arrow {
  width: 9px;
  height: 18px;
}

.header__title {
  font-weight: 600;
  font-size: 15px;
  line-height: 12px;
  margin-left: 10px;
  text-underline: none;
}

.header__logo {
  width: 100%;
  max-width: 162px;
}

.header__nav {
  display: flex;
  flex-flow: row;
  gap: 50px;
  margin-left: 70px;
}

.header__link {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  padding: 5px 0 15px 0;
  text-decoration: none;
  color: var(--brand-color-7);
  transition: font-weight 0.3s;
}

.header__link_active {
  color: #d273fa;
  border-bottom: 2px solid #d273fa;
}

.header__actions {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 25px;
  margin-left: auto;
  color: #8e9baa;
}

@media (max-width: 768px) {
  .header {
    padding: 15px;
    align-items: center;
  }

  .header__mobile-back {
    display: flex;
  }

  .header__actions {
    gap: 20px;
  }

  .header__logo,
  .header__nav,
  .header__projects,
  .header__socials {
    display: none;
  }
}
