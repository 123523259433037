.broadcasts {
    padding-bottom: 42px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
}

.broadcasts__empty {
    margin: auto;
}

.broadcasts__empty-table-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 1.02571px;
    color: var(--brand-color-1);
    transition: color .3s;
}

.broadcasts__empty-table-subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--brand-color-7);
    transition: color .3s;
}

.broadcasts__create {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    color: inherit;
    font-size: inherit;
    cursor: pointer;
    text-decoration: underline;
}

.broadcast-table {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #535353;
}

.broadcast__table {
    margin-top: 33px;
}

.broadcast-table__row {
    box-shadow: var(--box-shadow);
    border-radius: 28px;
    padding: 30px 32px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-radius: 28px;
    color: var(--brand-color-1);

    background-image: var(--brand-gradient-1);
    background-position: left;
    background-size: 300%;
    transition:
        background-position 0.3s linear,
        border 0.3s,
        color 0.3s;
}

.broadcast-table__row:first-child {
    background: transparent;
    border: none;
}

.broadcast-table__row+.broadcast-table__row {
    margin-top: 20px;
}

.broadcast-table__row:not(.broadcast-table__row_thead):hover {
    box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
    background-position: right;
}

.broadcast-table__row_thead {
    background-color: transparent;
    box-shadow: none;
    border: none;
    text-align: center;
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--brand-color-1);
    transition: color .3s;
}

.broadcast-table__td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.broadcast-table__td_center {
    text-align: center;
}

.broadcast-table__action-list {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.broadcast-table__action {
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #7868A7;
}

.broadcast-table__action:disabled {
    visibility: hidden;
}

.broadcasts__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.broadcasts__title {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1.02571px;
    color: var(--brand-color-1);
    transition: color .3s;
}

.broadcasts__archive-button {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--brand-color-4);
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 13px 16px;
    margin: 0;
    border-radius: 8px;
    transition: color .3s;
}

.broadcasts__archive-button_active {
    background-color: var(--background-color-4);
    border-color: var(--border-color-5);
    box-shadow: var(--box-shadow);
}


@media (max-width: 768px) {
    .broadcast-table__row {
        grid-template-columns: repeat(2, 1fr);

        padding: 26px 24px;
    }

    .broadcast-table__action-list {
        justify-content: flex-end;
    }

    .broadcast-table__action {
        width: 20px;
        height: 20px;
    }

    .broadcast-table__row:first-child {
        background: var(--list-1);
        border: 1px solid var(--list-2);
    }
}