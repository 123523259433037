.notifications {
  max-height: 600px;
  width: 380px;
  display: flex;
  flex-flow: column;
  border-radius: 8px;
  border: 1px solid var(--border-color-1);
  background: var(--background-color-2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

/* Header */
.notifications__header {
  z-index: 1;
  position: sticky;
  top: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid var(--border-color-1);
  background: var(--background-color-2);
}

.notifications__title {
  margin: 0;
  color: var(--brand-color-7);
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
}

.notifications__close {
  display: none;
  width: 14px;
  height: 14px;
  color: var(--brand-color-7);
}

/* Notification list */
.notifications__body {
  display: flex;
  flex-flow: column;
  overflow: auto;
}

@media (max-width: 768px) {
  .notifications {
    border-radius: 0;
    border: 0;
    width: 100%;
    height: 100%;
  }

  .notifications__close {
    display: block;
    width: 12px;
  }
}
