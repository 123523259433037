.signup {
    display: grid;
    row-gap: 12px;
}

.signup .signup__popover { /* Повышенный приоритет чтобы не влиял порядок подключения стилей при сборке */
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 78px);
    color: var(--error-color);
    white-space: nowrap;
}

.signup__text_center {
    color: var(--brand-color-1);
    text-align: center;
}

.signup .checkbox_error {
    border-color: var(--error-color);
}

@media (max-width: 768px) {
    .signup .signup__popover {
        position: relative;
        transform: none;
        top: initial;
        left: initial;
        background: transparent;
        box-shadow: none;
        padding: 10px 5px;
        white-space: initial;
    }
}