.tariffs {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 87px;
}

.tariffs__proposal-text {
    margin-top: 49px;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: var(--brand-color-8);
    transition: color .3s;
}

.curren-tariff {
    background-color: var(--background-color-2);
    width: 100%;
    max-width: 743px;
    border: 1px solid var(--border-color-1);
    border-radius: 28px;
    padding: 33px 36px 37px 38px;
    display: grid;
    grid-template-columns: auto 36%;
    column-gap: 32px;
    row-gap: 20px;
    box-sizing: border-box;
    color: var(--brand-color-6);
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.6px;
    transition: background-color .3s, color .3s, border .3s ;
}

.curren-tariff_indent-big {
    margin-top: 30px;
}

.curren-tariff_indent-small {
    margin-top: 20px;
}

.current-tariff__value {
    text-align: right;
}

.current-tariff__value_gradient {
    background: var(--main-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.current-tariff__value-infinity {
    font-size: 32px;
}

.tariffs__tabs {
    margin: 30px 0 0;
    padding: 0;
    list-style-type: none;
    display: flex;
}

.tariffs__tabs::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 33px;
    height: 33px;
    background-image: url('./img/discount.svg');
    background-repeat: no-repeat;
    background-size: 35px;
    background-position: -40% -45%;
    transform: translate(40%, -40%);
}

.tabs {
    --change-tab-duration: 100ms;

    color: var(--brand-color-1);
    position: relative;
    background-color: var(--background-color-2);
    border-radius: 8px;
    transition: color .3s, background-color .3s;
}

.tabs::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    background: var(--main-gradient);
    box-shadow: 0px 5px 7px rgba(82, 67, 194, 0.230196);
    border-radius: 8px;
    transition: transform var(--change-tab-duration) linear;
}

.tabs_active-0::before {
    transform: translateX(0%);
}

.tabs_active-1::before {
    transform: translateX(100%);
}

.tabs__item {
    position: relative;
    width: 111px;
    text-align: center;
    padding: 15px 0;
    transition: color var(--change-tab-duration) linear;
    cursor: pointer;
}

.tabs__item_active {
    color: #fff;
}

.card-list {
    display: flex;
    padding: 0;
    margin: 102px 0 0;
    list-style-type: none;
    background-color: var(--background-color-2);
    border-radius: 8px;
    width: 100%;
    max-width: 985px;
    border: 1px solid var(--card-list-border);
    transition: background-color .3s, border .3s;
}

.card {
    --border-radius: 8px;

    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: var(--border-radius);
    width: 33.333%;
    padding: 40px 50px;
    box-sizing: border-box;
    color: var(--brand-color-1);
    background: var(--background-color-2);
    transition: color .3s, background .3s;
}

.card:nth-child(2) {
    transform: translateY(-37px);
    background-image: var(--main-gradient);
    box-shadow: 0px 42px 34px rgba(82, 67, 194, 0.3);
    color: #fff;
    z-index: 2;
}

.card:nth-child(2) .card__description,
.card:nth-child(2) .card__period {
    color: inherit;
}

.card:nth-child(2) .card__option::before {
    background-image: url('./img/check-mark-solid.svg');
}

.card__mark {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 7px 26px;
    background-color: #4031B3;
    border-radius: 8px;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.833333px;
    color: #FFF;
}

.card__price-info {
    font-size: 36px;
    font-weight: 500;
    line-height: 46px;
    position: relative;
}

.card__promo-price {
    margin-bottom: -10px;
}

.card__price_crossed {
    font-size: 24px;
    text-decoration: line-through;
}

.card__period {
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #848199;
    vertical-align: middle;
    display: inline-block;
    margin-left: 0.4em;
}

.card__title {
    margin-top: 20px;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
}

.card__description {
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #848199;
    margin-top: 13px;
}

.card__option-list {
    --padding-left: 30px;
    
    margin: 14px 0 22px;
    padding: 0 0 0 var(--padding-left);
    list-style-type: none;
    display: grid;
    row-gap: 15px;
}

.card__option {
    position: relative;
}

.card__option::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(var(--padding-left) * -1);
    width: 20px;
    height: 20px;
    background: rgba(82, 67, 194, 0.103693) url('./img/check-mark-gradient.svg');
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
}

.card .card__button {
    margin-top: auto;
    background-color: var(--background-color-5);
    border-color: transparent;
    transition: background-color .3s, border-color .3s;
}

.card:nth-child(2) .card__button {
    background-color: var(--background-color-2);
    border-color: var(--border-color-3);
}

.card_custom .card__title {
    margin-top: 65px;
}

.info-message {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 1.02571px;
    color: var(--brand-color-1);
    margin-top: 10px;
}

.info-message_hide {
    display: none;
}

.card__year {
    display: none;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #848199;
    position: absolute;
    bottom: -10px;
    left: 0;
}

.card__year_show {
    display: block;
}

.card:nth-child(2) .card__year {
    color: #fff;
}

@media (max-width: 768px) {
    .tariffs {
        padding: 24px 16px 87px;
    }

    .curren-tariff {
        padding: 20px 15px;
        font-size: 16px;
    }

    .tariffs__proposal-text {
        font-size: 16px;
        max-width: 300px;

        margin-top: 30px;
    }

    .tabs  {
        margin-top: 35px;
    } 

    .card-list {
        margin-top: 24px;

        flex-flow: column;
        align-items: center;
        gap: 24px;

        background: initial;
        border: none;
    }

    .card {
        width: 100%;
        max-width: 292px;

        padding: 30px 25px;

        box-shadow: 0px 42px 34px rgba(82, 67, 194, 0.295755) !important;
    }

    .card:nth-child(1) {
        order: 1;
        transform: initial;

        z-index: 1;
    }

    .card:nth-child(2) {
        order: 0;
        transform: initial;

        z-index: 0;
    }

    .card:nth-child(3) {
        order: 2;
        transform: initial;

        z-index: 2;
    }

}