.input-field {
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    box-sizing: border-box;
    transition: border .3s;
}

.input-field .input-field__input_focus {
    box-shadow: var(--focus-shadow);
    transition: border-color .3s, box-shadow .3s;
}

.input-field .input-field__input_disabled {
    opacity: 20%;
}

.input-field .input-field__input_error {
    border-color: var(--error-color);
    transition: border-color .3s;
}

.input-field__counter {
    color: var(--brand-color-1);
    position: absolute;
    font-size: 12px;
    right: 0;
    bottom: -18px;
}

.input-field__input {
    height: 100%;
    border: 1px solid var(--border-color-6);
    background-color: var(--input-background);
    padding: 13px 16px;
    font-family: inherit;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    border-radius: inherit;
    width: 100%;
    outline: none;
    color: var(--brand-color-1);
    transition: background-color .3s, color .3s;
}

.input-field__error {
    margin-top: 10px;
    font-size: 13px;
    color: var(--error-color);
}

.input-field__input::-webkit-input-placeholder,
.input-field__input::placeholder {
    color: #C8C8C8;
    font-family: inherit;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.input-field_purple.input-field_focus {
    border-color: var(--focus-input);
}

.input-field_gray.input-field_focus {
    border-color: var(--border-color-6);
}