.title__button {
  padding: 0 0 10px 0;

  font-size: 16px;
  color: var(--brand-color-5);
  font-family: 'Mulish', sans-serif;

  background: transparent;
  border: none;

  border-bottom: 2px solid var(--border-color-8);

  transition: border .3s;
}

.title__button.active {
  font-weight: 700;
  border-bottom: 2px solid var(--brand-color-5);
}

@media (max-width: 768px) {
  .title {
    flex: 1;
  }

  .title__button {
    width: 100%;
  }
}